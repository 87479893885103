import { lazy } from "react";

const mobileComponents = {
  "m-banner-carousel": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-banner-carousel/m-banner-carousel"
      )
  ),
  "m-card-destination-carousel": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-card-destination-carousel/m-card-destination-carousel"
      )
  ),
  "m-featured-cards": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-featured-cards/m-featured-cards"
      )
  ),
  "m-features-list-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-features-list-traveltool/m-features-list-traveltool"
      )
  ),
  "m-footer-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-footer-traveltool/m-footer-traveltool"
      )
  ),
  "m-header-traveltool": lazy(
    () => import("../components/alexandria/mobile/m-header-traveltool/m-header-traveltool")
  ),
  "m-newsletter-subscription": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-newsletter-subscription/m-newsletter-subscription"
      )
  ),
  "m-tabs-campaign": lazy(
    () =>
      import("../components/alexandria/mobile/m-tabs-campaign/m-tabs-campaign")
  ),
  "m-tabs-searchers": lazy(
    () =>
      import(
        "../components/alexandria/mobile/m-tabs-searchers/m-tabs-searchers"
      )
  ),
};

export default class MobileComponentMapper {
  static getMappedComponent(componentName: string) {
    return mobileComponents[componentName];
  }
}
