import { forwardRef } from "react";
import { Image, View, Text, Svg, Path } from "@react-pdf/renderer";
import "./pdf-contact.css";
import style from "./pdf-contact.style";
import icons from "./icons/icons";
import { PdfContactProps } from "./props/pdf-contact.props";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";
import PdfText from "../pdf-text/pdf-text";

export default forwardRef<HTMLElement, PdfContactProps>(function PdfContact({
  account,
  address,
  bio,
  contactLiteral,
  email,
  logoUrl,
  name,
  phone,
  pdfStyles,
  web
}: Readonly<PdfContactProps>) {
  return (
    <>
    <View style={style.container}>
      <View >
        <View style={style.accountBox}>
          <Text style={{...style.account, color: pdfStyles.primaryColor700 ?? "#000000" }}>{account}</Text>
        </View>
        <View style={style.titleBox}>
          <View style={style.imageBox}>
            { logoUrl && (<Image style={style.titleImage} src={ImageUrlProxyUtils.processImageUrl(logoUrl)} />) }
          </View>
          <View>
            <Text style={{...style.infoAgent, color: pdfStyles.primaryColor700 ?? "#000000"}}>{contactLiteral}</Text>
            <Text style={{...style.infoTitle, color: pdfStyles.primaryColor700 ?? "#000000" }}>{name}</Text>
          </View>
        </View>
      </View>
      <View style={style.infoBox}>
        {address && 
          (<View style={style.info}>
            <Svg style={style.iconTitle} viewBox="0 0 24 24">
              <Path fill="#667b99" d={icons["ri-map-pin-line"]} />
            </Svg>
            <Text style={{color: pdfStyles.primaryColor700 ?? "#000000"}}>
              {address}
            </Text>
          </View>)
        }
        {web && 
          (<View style={style.info}>
            <Svg style={style.iconTitle} viewBox="0 0 24 24">
              <Path fill="#667b99" d={icons["ri-link-line"]} />
            </Svg>
            <Text style={{color: pdfStyles.primaryColor700 ?? "#000000"}}>
              {web}
            </Text>
          </View>)
        }
        {email &&
          (<View style={style.info}>
            <Svg style={style.iconTitle} viewBox="0 0 24 24">
              <Path fill="#667b99" d={icons["ri-mail-line"]} />
            </Svg>
            <Text style={{color: pdfStyles.primaryColor700 ?? "#000000"}}>
              {email}
            </Text>
          </View>)
        }
        {phone &&
          (<View style={style.info}>
            <Svg style={style.iconTitle} viewBox="0 0 24 24">
              <Path fill="#667b99" d={icons["ri-phone-line"]} />
            </Svg>
            <Text style={{color: pdfStyles.primaryColor700 ?? "#000000"}}>
              {phone}
            </Text>
          </View>)
        }
      </View>
    </View>
    <View>
      {bio && (<PdfText Text={bio} pdfStyles={pdfStyles} />)}
    </View>
    </>
  );
}
);