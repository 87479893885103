import { createPortal } from "react-dom";
import ImagesModal from "../../images-modal/images-modal";
import { EventGalleryItemProps } from "./props/event-gallery-item.props";
import { Image } from "../../gallery/props/gallery.props";
import useModalState from "../../custom-hooks/use-modal-state";
import ImageUrlProxyUtils from "../../../../../utils/imageUrlProxy.utils";

export default function EventGalleryItem({
    children,
    className,
    imageId,
    imageUrl,
    images
}: Readonly<EventGalleryItemProps>) {
  const style = {
    backgroundImage: `url(${ImageUrlProxyUtils.processImageUrl(imageUrl)})`,
    border: "none",
  };

  const [showModal, {createModal, closeModal}] = useModalState();

  return (
    <>
      <button
        type="button"
        onClick={createModal}
        onKeyDown={createModal}
        key={imageId}
        style={style}
        className={`proposal-images__${className}`}
        data-testid="btn_images"
        >
        {children}
      </button>
      {showModal &&
        createPortal(
          <ImagesModal
            key={imageId}
            onClose={() => closeModal()}
                images={images.map((image: string) => {
                    return { id: image, image_url: ImageUrlProxyUtils.processImageUrl(image)} as Image;
                 })}
            selectedImageIndex={images.findIndex(
              (x) => x === imageUrl
            )}
          />,
          document.body
        )}
    </>
  );
}
