import { useEffect, useState } from "react";
import { ProposalImageItemProps } from "./props/proposal-image-item";
import { createPortal } from "react-dom";
import ImagesModal from "../../images-modal/images-modal";

export default function ProposalImageItem({
  image,
  images,
  className,
  count,
}: Readonly<ProposalImageItemProps>) {
  const [showModal, setShowModal] = useState(false);

  const createModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  const imageStyle = {
    backgroundImage: `url(${image.image_url || image.url || image.original})`,
  };

  className = `proposal-images__image ${className}`;

  return (
    <>
      <button
        type="button"
        onClick={createModal}
        onKeyDown={createModal}
        key={image.id}
        style={imageStyle}
        className={className}
      >
        {BuildCounter(count)}
      </button>
      {showModal &&
        createPortal(
          <ImagesModal
            onClose={() => setShowModal(false)}
            images={images}
            selectedImageIndex={images.findIndex((x) => x === image)}
          />,
          document.body
        )}
    </>
  );
}

const BuildCounter = (count: number) => {
  if (count <= 1) return;
  return (
    <div className="proposal-images__counter">
      <div className="proposal-images__counter-number">{count}</div>
      <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
        <path d="M20 5H4v14l9.292-9.294a1 1 0 0 1 1.414 0L20 15.01V5zM2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
      </svg>
    </div>
  );
};
