import ImageUrlProxyUtils from "../../../../utils/imageUrlProxy.utils";
import { CardPriceProposal, ProductInfoProps } from "../product-card/props/product-card.props";
import './banner.css';

export default function Banner({ ...props }: Readonly<ProductInfoProps>) {

  const {
    ImageUrl,
    Title,
    NumDays,
    ProposalPrice,
  } = props;

  let priceFromToString: { [id: number]: string } = {};
  priceFromToString[CardPriceProposal.from] = ProposalPrice.split(' ')[0] ?? '';
  priceFromToString[CardPriceProposal.to] = ProposalPrice.replace(priceFromToString[CardPriceProposal.from], '') ?? '';

  return (
    <div data-testid="banner" id="banner">
      <div className="product-info">
          <div className="banner-info">
            <div className="banner-title">
              <h2>{Title}</h2>
            </div>
            <div className="days">
              <h4>{NumDays}</h4>
            </div>
            {priceFromToString[CardPriceProposal.to].trim() != '' &&
            <div className="price-info">
              {priceFromToString[CardPriceProposal.from]}
              {priceFromToString[CardPriceProposal.to]}
            </div>}
          </div>
        </div>
      <div className="image">
        <img className={"carousel-image"} src={ImageUrlProxyUtils.processImageUrl(ImageUrl)} alt="banner image" />
      </div>
      <div className="image">
        <img className={"banner-carousel-image"} src={ImageUrlProxyUtils.processImageUrl(ImageUrl)} alt="banner image" />
      </div>
    </div>
  );
}